
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import less from "less";

	@Component
	export default class Loading extends Vue {
		@Prop({ default: 200, type: Number }) readonly width!:number;
		@Prop({ default: 200, type: Number }) readonly height!:number;
		private MainLink:HTMLLinkElement = document.createElement("link");

		mounted ():void {
			this.renderCss();
		}

		@Watch("$store.state.baseColor")
		onColorChange () {
			this.renderCss();
		}

		beforeDestroy () {
			this.MainLink && this.MainLink.remove();
		}

		private renderCss () {
			less.render("@darkBackground: @baseColor;.LoadingBox {width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;background-color: @darkBackground;}", {
				javascriptEnabled: true,
				modifyVars: {
					"@baseColor": this.$store.state.baseColor,
					"path": "/static/UnityWeb/desktop/"
				},
				paths: [ "/static/UnityWeb/desktop/" ]
			}).then(({ css }) => {
				this.MainLink && this.MainLink.remove();
				const href:string = URL.createObjectURL(new Blob([ css ]));
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
				this.MainLink = link;
			});
		}
	}

